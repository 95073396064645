import React from "react";

import { Link } from "gatsby";

import PromoDataGrid from "../../components/promos/PromoDataGrid";
import SEO from "../../components/SEO";

import PrivateRoute from "~/src/components/Authentication/MiducPrivateRoute";
import TokenValidator from "~/src/components/Authentication/MiducTokenValidator";
import Layout from "~/src/components/Layout";

const Coupons = (): JSX.Element => {
  return (
    <Layout>
      {/* @ts-expect-error TokenValidator cannot be used as a JSX element */}
      <TokenValidator>
        {/* @ts-expect-error PrivateRoute cannot be used as a JSX element */}
        <PrivateRoute>
          <>
            <SEO title="Examedi Marketing Promos" />
            <div className="min-h-screen grid w-full bg-gray-100 relative overflow-y-auto pt-2 px-4">
              <div className="p-10 flex flex-col h-full">
                <div className="mb-7 flex items-center flex-grow-0">
                  <h1 className="mr-8 text-4xl">Fotos de Promoción</h1>
                  <Link
                    to="/promos/new"
                    className="p-2 text-sm hover:bg-gray-100 underline uppercase text-blue-400 hover:no-underline"
                  >
                    Agregar promo
                  </Link>
                </div>
                <div className="w-full bg-white flex-grow">
                  <PromoDataGrid />
                </div>
              </div>
            </div>
          </>
        </PrivateRoute>
      </TokenValidator>
    </Layout>
  );
};

export default Coupons;
