import React, { useState, useEffect } from "react";

import LinearProgress from "@material-ui/core/LinearProgress";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { DataGrid, GridOverlay, GridToolbarContainer, GridToolbar } from "@material-ui/data-grid";
import clsx from "clsx";
import moment from "moment";

import { DynamicAsset } from "../../types/promos";

import { CountryLower } from "~/src/constants/countries";
import { listPromos } from "~/src/utils/api-promos";
import { capitalizeFirstLetter } from "~/src/utils/text";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbar />
    </GridToolbarContainer>
  );
}

const PromoDataGrid = () => {
  const [promos, setPromos] = useState<DynamicAsset[]>([]);
  const [country, setCountry] = useState<CountryLower>(CountryLower.CHILE);
  const [loading, setLoading] = useState<boolean>(false);

  const renderCustomLoadingOverlay = () => {
    return (
      <GridOverlay>
        <div className="absolute top-0 w-full">
          <LinearProgress />
        </div>
      </GridOverlay>
    );
  };

  useEffect(() => {
    const fetchPromos = async () => {
      setLoading(true);
      const res = await listPromos(country);
      setPromos(res);
      setLoading(false);
    };

    fetchPromos();
  }, [country]);

  const columns = [
    {
      field: "index",
      headerName: "index",
      type: "number",
      sorteable: false,
      hide: true,
    },
    {
      field: "name",
      headerName: "Nombre",
      sortable: true,
      type: "string",
      width: 200,
      renderHeader: () => <strong>Nombre</strong>,
    },
    {
      field: "image_url",
      headerName: "Enlace Imagen",
      sortable: true,
      type: "string",
      width: 250,
      renderHeader: () => <strong>Enlace Imagen</strong>,
      renderCell: (params: { row: DynamicAsset & { index: number } }) => {
        return (
          <a className="text-blue-600 hover:underline" href={params.row.image_url} target="_blank" rel="noreferrer">
            Ver imagen
          </a>
        );
      },
    },
    {
      field: "redirection_url",
      headerName: "Enlace de Redirección",
      sortable: true,
      type: "string",
      width: 250,
      renderHeader: () => <strong>Enlace de Redirección</strong>,
      renderCell: (params: { row: DynamicAsset & { index: number } }) => {
        if (!params.row.redirection_url) {
          return <p>Sin enlace de redirección</p>;
        }
        return (
          <a
            className="text-blue-600 hover:underline"
            href={params.row.redirection_url}
            target="_blank"
            rel="noreferrer"
          >
            Seguir enlace
          </a>
        );
      },
    },
    {
      field: "created_at",
      headerName: "Fecha Creación",
      sortable: true,
      type: "date",
      width: 200,
      renderHeader: () => <strong>Fecha Creación</strong>,
      valueFormatter: (params: { row: DynamicAsset & { index: number } }) => {
        return moment(params.row.created_at).format(`DD/MM/YYYY`);
      },
    },
    {
      field: "",
      headerName: "Info",
      sortable: false,
      type: "string",
      width: 290,
      renderHeader: () => <strong>Información</strong>,
      renderCell: (params: { row: DynamicAsset & { index: number } }) => {
        if (params.row.index === 0) {
          return (
            <p>
              Esta se mostrará en{" "}
              <a
                className="text-blue-600 hover:underline"
                href="https://examedi.com/promo"
                target="_blank"
                rel="noreferrer"
              >
                examedi.com/promo
              </a>
            </p>
          );
        }
      },
    },
  ];

  return (
    <>
      <div className={clsx("w-1/2", "ml-2 px-2 py-4", "flex flex-col")}>
        <p className={clsx("mb-2", "text-lg")}>Elige un país...</p>
        <Select value={country} onChange={(e) => setCountry(e.target.value as CountryLower)} fullWidth>
          {Object.entries(CountryLower).map(([key, value]) => (
            <MenuItem key={key} value={value}>
              {capitalizeFirstLetter(value)}
            </MenuItem>
          ))}
        </Select>
        <p className={clsx("mt-3", "text-sm text-gray-600")}>
          Actualmente viendo las promociones de {capitalizeFirstLetter(country)}
        </p>
      </div>
      <DataGrid
        components={{
          LoadingOverlay: renderCustomLoadingOverlay,
          Toolbar: CustomToolbar,
        }}
        loading={loading}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        columns={columns}
        rows={promos.map((promo, index) => ({ index, ...promo }))}
        pageSize={10}
        disableSelectionOnClick
      />
    </>
  );
};

export default PromoDataGrid;
